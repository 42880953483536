import product1 from "../assets/components/products/espresso.jpg";
import product3 from "../assets/components/products/ristreto.jpg";
import product4 from "../assets/components/products/americano.jpg";
import product5 from "../assets/components/products/coffee.jpg";
import product6 from "../assets/components/products/cappuccino.jpg";
import product8 from "../assets/components/products/mocaccino.jpg";
import product9 from "../assets/components/products/carioca.jpg";
import product10 from "../assets/components/products/cortado.jpg";
import product11 from "../assets/components/products/pingado.jpg";
import product12 from "../assets/components/products/affogato.jpg";
import product13 from "../assets/components/products/caramel-iced-coffee.jpg";
import product14 from "../assets/components/products/orange-tonic-espresso.jpg";
import product15 from "../assets/components/products/frappe.jpg";
import product17 from "../assets/components/products/cheesecake.jpg";
import product19 from "../assets/components/products/seni.jpg";
import product21 from "../assets/components/products/brownie-chocolate-pie.jpg";
import product22 from "../assets/components/products/nina.jpg";
import product23 from "../assets/components/products/brownie.jpg";
import product27 from "../assets/components/products/empada.jpg";
import product28 from "../assets/components/products/quiche.jpg";
import product29 from "../assets/components/products/pizza.jpg";
import product30 from "../assets/components/products/brazilian-cheese-bread.jpg";
import product32 from "../assets/components/products/bread.jpg";
import product33 from "../assets/components/products/toast.jpg";
import product34 from "../assets/components/products/lulu.jpg";
import product35 from "../assets/components/products/maria.jpg";
import product36 from "../assets/components/products/bears.jpg";
import product39 from "../assets/components/products/aperol.jpg";
import product40 from "../assets/components/products/caipirinha.jpg";
import product43 from "../assets/components/products/gin-tonic.jpg";
import product44 from "../assets/components/products/mojito.jpg";
import product46 from "../assets/components/products/irish-coffee.jpg";
import product48 from "../assets/components/products/tea.jpg";
import product49 from "../assets/components/products/hot-chocolate.jpg";
import product50 from "../assets/components/products/frozen.jpg";
import product51 from "../assets/components/products/ice-tea.jpg";
import product52 from "../assets/components/products/soda.jpg";
import product54 from "../assets/components/products/italian-soda.jpg";
import product56 from "../assets/components/products/juice.jpg";
import product57 from "../assets/components/products/water.jpg";
import product58 from "../assets/components/products/chocolate-pie.jpg";
import product59 from "../assets/components/products/mini-pizza.jpg";
import product60 from "../assets/components/products/ice-latte.jpg";
import product61 from "../assets/components/products/nina-mocca.jpg";
import product62 from "../assets/components/products/sandwich.jpg";
import product63 from "../assets/components/products/bread-fried-egg.jpg";
import product64 from "../assets/components/products/bread-omelet.jpg";
import product65 from "../assets/components/products/goncalo.jpg";
import product66 from "../assets/components/products/chocolate-love.jpg";
import product67 from "../assets/components/products/quindim.jpg";
import product68 from "../assets/components/products/tonic-water.jpg";

export const INPUT = {
  text: "Buscar",
  styles: {
    width: "70%",
    color: "#d93250",
    border: "0.2em solid #d93250",
    borderRadius: "20px 0 0 20px",
  },
};

export const BUTTON = {
  text: "Limpar",
  styles: {
    width: "30%",
    color: "#e9f5eb",
    border: "0.2em solid #d93250",
    borderRadius: "0 20px 20px 0",
  },
};

export const CATEGORIES = [
  {
    id: 3,
    name: "Exclusivos do Nina",
    products: [
      {
        id: 31,
        image: product32,
        name: "Pão Caseiro",
        price: 5,
        description:
          "Fatia de pão caseiro para comer com uma geléia a sua escolha",
        flavors: ["Damasco", "Morango"],
        options: [
          {
            id: 1,
            name: "Para levar",
            description: "Pão Caseiro inteiro(500g) para levar para casa",
            price: 22,
          },
        ],
        subCategories: ["Salgado"],
      },
      {
        id: 64,
        name: "Pão com omelete cremosa",
        image: product64,
        price: 19.9,
        description:
          "Fatia de pão tostada com omelete cremosa, feita com ovo e nata",
        subCategories: ["Salgado"],
      },
      {
        id: 19,
        image: product19,
        name: "Torta Seni",
        price: 19.9,
        description: "Pão de ló, amendoim com leite condensado e nata",
        subCategories: ["Doce", "Torta"],
      },
      {
        id: 23,
        name: "Brownie",
        image: product23,
        price: 8.5,
        description: "Massa de bolo de chocolate ao leite e meio amargo",
        flavors: ["Chocolate", "Chocolate com nozes", "Chocolate branco"],
        options: [
          {
            id: 1,
            name: "Com sorvete",
            price: 20,
            description: "Adicona uma bola de sorvete por cima",
            flavors: ["Creme"],
          },
        ],
        subCategories: ["Doce"],
      },
      {
        id: 33,
        image: product33,
        name: "Torrada de pão caseiro",
        price: 19.9,
        description:
          "Torra feita com 2 fatias do delicioso pão caseiro e recheio a sua escolha",
        flavors: ["Queijo com salame", "Queijo com presunto"],
        subCategories: ["Salgado"],
      },
      {
        id: 62,
        name: "Sanduiche",
        image: product62,
        price: 32.9,
        description:
          "Fatias de pão de casa tostadas, alface americana crocante, picles, tomate cereja, maionese, molho pesto caseiro e carne a escolha",
        flavors: ["Carne ao molho vermelho", "Frango"],
        subCategories: ["Salgado"],
      },
      {
        id: 63,
        name: "Pão com ovo frito",
        image: product63,
        price: 16.9,
        description: "Fatia de pão tostada com ovo frito",
        subCategories: ["Salgado"],
      },
      {
        id: 66,
        name: "Amor de chocolate",
        image: product66,
        description: "Um delicioso mini bolo com cobertura",
        options: [
          {
            id: 1,
            name: "Chocolate com cobertura de brigadeiro",
            price: 14.9,
          },
          {
            id: 2,
            name: "Cenoura com cobertura de brigadeiro",
            price: 14.9,
          },
        ],
        subCategories: ["Salgado"],
      },
    ],
  },

  {
    id: 1,
    name: "Delícias",
    products: [
      {
        id: 17,
        name: "Cheesecake",
        image: product17,
        price: 16.9,
        flavors: ["Frutas Vermelhas"],
        description:
          "Torta com base crocante de biscoito de maisena, com massa de cream cheese e coberta com geleia de frutas vermelhas",
        subCategories: ["Doce", "Torta"],
      },
      {
        id: 21,
        name: "Torta de Brownie de Chocolate",
        image: product21,
        price: 16.9,
        description:
          "Torta de massa de brownie vegano, ganache de chocolate 70% e cobertura de geleia de frutas vermelhas",
        subCategories: ["Doce", "Vegano", "Torta"],
      },
      {
        id: 22,
        name: "Torta Nina",
        image: product22,
        price: 19.9,
        description:
          "Bolo de baunilha com recheio de quatro leites(leite condensado, creme de leite, leite coco e leite em pó) e brigadeiro de leite ninho",
        subCategories: ["Doce", "Torta"],
      },
      {
        id: 27,
        name: "Empadas",
        image: product27,
        price: 14,
        description:
          "Massa quebradiça a base de manteiga com recheios variados",
        flavors: [
          "Cebola caramelizada com ricota e gorgonzola",
          "Camarão",
          "Carne de panela",
          "Frango",
          "Palmito",
        ],
        subCategories: ["Salgado"],
      },
      {
        id: 28,
        name: "Quiche",
        image: product28,
        price: 18,
        description:
          "Massa sablé clássica, recheio a base de ovos e creme de leite com toque de noz-moscada",
        flavors: [
          "Alho poró",
          "Carne de panela",
          "Cogumelos",
          "Frango",
          "Tomate cereja com queijo colonial",
          "Gorgonzola com cebola caramelizada",
        ],
        subCategories: ["Salgado"],
      },
      {
        id: 29,
        image: product29,
        name: "Pizza",
        price: 42.9,
        description:
          "Massa fininha bem crocante com molho de tomate e queijo mussarela",
        flavors: ["Basca", "Calabresa", "Margherita", "Quatro queijos"],
        subCategories: ["Salgado"],
      },
      {
        id: 30,
        name: "Pão de queijo",
        image: product30,
        price: 6,
        subCategories: ["Salgado"],
      },
      {
        id: 34,
        name: "Torta Lulu",
        image: product34,
        price: 16.9,
        description:
          "Torta com base crocante de biscoito de maisena, mousse de limão com pedaços de chocolate branco e merengue suíço",
        subCategories: ["Doce", "Torta"],
      },
      {
        id: 35,
        name: "Torta Maria",
        image: product35,
        price: 19.9,
        description: "Torta de pão de ló, brigadeiro de coco e pistache",
        subCategories: ["Doce", "Torta"],
      },
      {
        id: 58,
        name: "Torta de Chocolate",
        image: product58,
        price: 19.9,
        description:
          "Torta feita em 8 camadas, sendo 4 delas com massa de bolo de chocolate e as outras 4 sendo recheio de brigadeiro de chocolate meio amargo",
        subCategories: ["Doce", "Torta"],
      },
      {
        id: 59,
        name: "Mini pizza",
        image: product59,
        price: 18,
        description:
          "Massa de farinha de arroz integral, farinha de grão-de-bico, linhaça, amido de milho, batata e mandioca e azeite de oliva extra virgem",
        flavors: ["Alho-poró", "Margherita com pesto"],
        subCategories: ["Sem glúten", "Vegano"],
      },
      {
        id: 67,
        name: "Quindim",
        image: product67,
        price: 7.9,
        subCategories: ["Doce"],
      },
    ],
  },
  {
    id: 2,
    name: "Bebidas",
    products: [
      {
        id: 1,
        name: "Espresso",
        image: product1,
        strength: 4,
        price: 8,
        description:
          "Uma dose de café preparado através da passagem de água quente, não fervente, sob alta pressão pelo café moído",
        options: [
          {
            id: 1,
            name: "Duplo",
            price: 10,
          },
        ],
        subCategories: ["Bebida", "Café", "Quente", "Sem álcool"],
      },
      {
        id: 3,
        name: "Ristreto",
        image: product3,
        strength: 5,
        price: 8,
        description: "Um shot da parte mais forte do café expresso",
        subCategories: ["Bebida", "Café", "Quente", "Sem álcool"],
      },
      {
        id: 4,
        name: "Americano",
        image: product4,
        strength: 2,
        description: "Uma dose de café(expresso ou coado) e água",
        price: 11,
        subCategories: ["Bebida", "Café", "Quente", "Sem álcool"],
      },
      {
        id: 5,
        name: "Filtrado/Passado",
        image: product5,
        strength: 1,
        price: 8,
        options: [
          {
            id: 1,
            name: "Refil",
            description: "Tome o quanto de café passado quiser durante 5 horas",
            price: 20,
          },
        ],
        subCategories: ["Bebida", "Café", "Quente", "Sem álcool"],
      },
      {
        id: 6,
        name: "Cappuccino",
        image: product6,
        strength: 3,
        options: [
          {
            id: 1,
            name: "Brasileiro",
            description:
              "Feito com solução em pó que contém café, leite em pó integral, cacau, açúcar e canela. Chantilly, (opcional)",
            price: 15,
          },
          {
            id: 2,
            name: "Italiano",
            description:
              "Expresso curto(uma dose de expresso), leite integral vaporizado com crema ou vegetal(leite naveia) e canela polvilhada",
            price: 15,
          },
        ],
        subCategories: [
          "Bebida",
          "Café",
          "Quente",
          "Sem álcool",
          "Leite vegetal",
        ],
      },
      {
        id: 8,
        name: "Mocaccino",
        image: product8,
        strength: 3,
        price: 18,
        description:
          "Expresso curto(uma dose de expresso), leite vaporizado com crema, calda de chocolate e canela polvilhada. Chantilly, (opcional)",
        subCategories: ["Bebida", "Café", "Quente", "Sem álcool"],
      },
      {
        id: 9,
        name: "Carioca",
        image: product9,
        strength: 1,
        price: 8,
        description:
          "Expresso curto(uma dose de expresso) sem a primeira coagem",
        options: [
          {
            id: 1,
            name: "Duplo",
            price: 10,
          },
        ],
        subCategories: ["Bebida", "Café", "Quente", "Sem álcool"],
      },
      {
        id: 10,
        name: "Cortado",
        image: product10,
        strength: 2,
        price: 12,
        description:
          "Uma expresso curto(uma dose de expresso) com leite vaporizado ou vegetal(leite naveia)",
        subCategories: [
          "Bebida",
          "Café",
          "Quente",
          "Sem álcool",
          "Leite vegetal",
        ],
      },
      {
        id: 11,
        name: "Pingado",
        image: product11,
        strength: 2,
        price: 12,
        description:
          "Café coado com pouco leite integral ou vegetal(leite naveia)",
        subCategories: [
          "Bebida",
          "Café",
          "Quente",
          "Sem álcool",
          "Leite vegetal",
        ],
      },
      {
        id: 12,
        name: "Affogato",
        image: product12,
        strength: 3,
        description:
          "Sorvete de baunilha acompanhado de uma dose quente de café expresso",
        price: 18.9,
        subCategories: ["Bebida", "Café", "Gelado", "Sem álcool"],
      },
      {
        id: 13,
        name: "Café Gelado de Caramelo",
        image: product13,
        strength: 2,
        price: 21.9,
        description:
          "Leite integral ou vegetal(leite naveia), cappuccino, calda de caramelo e xarope de caramelo salgado. Chantilly(opcional)",
        subCategories: [
          "Bebida",
          "Café",
          "Gelado",
          "Sem álcool",
          "Leite vegetal",
        ],
      },
      {
        id: 14,
        name: "Espresso Tônica com Laranja",
        image: product14,
        strength: 4,
        price: 16.9,
        description:
          "Expresso curto(uma dose de expresso), água tônica, suco de laranja e gelo",
        subCategories: ["Bebida", "Café", "Gelado", "Sem álcool"],
      },
      {
        id: 15,
        name: "Frappê",
        image: product15,
        strength: 3,
        price: 21.9,
        description:
          "Expresso curto(uma dose de expresso), leite integralm, calda saborizada salgada e gelo",
        flavors: ["Caramelo", "Chocolate"],
        subCategories: ["Bebida", "Café", "Gelado", "Sem álcool"],
      },
      {
        id: 46,
        name: "Irish Coffee",
        image: product46,
        strength: 4,
        description:
          "Expresso curto(uma dose de expresso) e uma dose de whisky com chantilly e raspas de laranja",
        price: 28,
        subCategories: ["Bebida", "Café", "Com álcool", "Quente"],
      },
      {
        id: 36,
        name: "Cerveja",
        image: product36,
        options: [
          {
            id: 3,
            name: "Heineken",
            price: 12,
          },
        ],
        subCategories: ["Bebida", "Com álcool", "Gelado"],
      },
      {
        id: 39,
        name: "Aperol Spritz",
        image: product39,
        price: 32,
        description: "Espumante prosecco, licor de aperol, laranja e gelo",
        subCategories: ["Bebida", "Com álcool", "Gelado"],
      },
      {
        id: 40,
        name: "Caipirinha",
        image: product40,
        description: "Suco de fruta, a fruta macerada, açucar e gelo",
        flavors: ["Limão", "Morango"],
        options: [
          {
            id: 1,
            name: "Com cachaça",
            price: 25,
          },
          {
            id: 2,
            name: "Com Vodka Smirnoff",
            price: 27,
          },
          {
            id: 3,
            name: "Com Vodka Absolut",
            price: 29,
          },
        ],
        subCategories: ["Bebida", "Com álcool", "Gelado"],
      },
      {
        id: 43,
        name: "Gin tônica",
        image: product43,
        description: "Limão, água tônica, gin e gelo",
        price: 29,
        subCategories: ["Bebida", "Com álcool", "Gelado"],
      },
      {
        id: 44,
        name: "Mojito",
        image: product44,
        description: "Run, hortelã, suco de limão e água com gás",
        price: 27,
        subCategories: ["Bebida", "Com álcool", "Gelado"],
      },
      {
        id: 48,
        name: "Chá",
        image: product48,
        price: 8,
        flavors: [
          "Camomila",
          "Frutas silvestres",
          "Hortelã",
          "Limão com gengibre",
          "Chá verde e Hortelã",
        ],
        subCategories: ["Bebida", "Sem álcool", "Quente"],
      },
      {
        id: 49,
        name: "Chocolate Quente",
        image: product49,
        price: 21.9,
        subCategories: ["Bebida", "Sem álcool", "Quente"],
      },
      {
        id: 50,
        name: "Frozen",
        image: product50,
        price: 21.9,
        description:
          "Leite integral, base de iogurte, morango, calda saborizada e gelo",
        flavors: ["Morango"],
        subCategories: ["Bebida", "Sem álcool", "Gelado"],
      },
      {
        id: 51,
        name: "Chá Mate",
        image: product51,
        price: 9,
        subCategories: ["Bebida", "Sem álcool", "Gelado"],
      },
      {
        id: 52,
        name: "Refrigerante",
        image: product52,
        options: [
          {
            id: 1,
            name: "Coca-cola(350ml)",
            price: 7.9,
          },
          {
            id: 2,
            name: "Coca-cola zero(350ml)",
            price: 7.9,
          },
          {
            id: 3,
            name: "Guaraná Antarctica(350ml)",
            price: 7.9,
          },
          {
            id: 4,
            name: "Guaraná Antarctica zero(350ml)",
            price: 7.9,
          },
        ],
        subCategories: ["Bebida", "Sem álcool", "Gelado"],
      },
      {
        id: 54,
        image: product54,
        name: "Soda Italiana",
        description: "Xarope, água com gás e gelo",
        price: 14,
        flavors: [
          "Cranberry",
          "Frutas vermelhas",
          "Limão siciliano",
          "Maça verde",
          "Morango",
          "Romã",
        ],
        subCategories: ["Bebida", "Sem álcool", "Gelado"],
      },
      {
        id: 56,
        name: "Suco",
        image: product56,
        price: 14,
        description: "Suco natural com gelo bem geladinho",
        flavors: ["Abacaxi", "Laranja", "Morango"],
        subCategories: ["Bebida", "Sem álcool", "Gelado"],
      },
      {
        id: 57,
        name: "Água",
        image: product57,
        price: 5,
        options: [
          {
            id: 1,
            name: "Com gás",
            price: 5,
          },
        ],
        subCategories: ["Bebida", "Sem álcool", "Gelado"],
      },
      {
        id: 60,
        name: "Latte gelado",
        image: product60,
        strength: 2,
        price: 16.9,
        description:
          "Leite vaporizado, café espresso, essência de baunilha e gelo",
        subCategories: ["Bebida", "Sem álcool", "Gelado", "Café"],
      },
      {
        id: 61,
        name: "Nina mocca",
        image: product61,
        strength: 1,
        price: 19.9,
        escription:
          "Uma bebida em 3 camadas onde a camada a primeira camada é feita com um delicoso ganache de chocolate branco seguida de leite vaporizado misturado com uma boa dose de espresso e coberto de chantilly e côco ralado",
        subCategories: ["Bebida", "Sem álcool", "Café", "Quente"],
      },
      {
        id: 65,
        name: "Gonçalo",
        image: product65,
        strength: 2,
        price: 32,
        description:
          "Frisante branco com uma dose equilibrada de xarope de maçã verde, gelados por uma esfera única de gelo e uma deliciosa fatia de maçã verde",
        subCategories: ["Bebida", "Com álcool", "Gelado"],
      },
      {
        id: 68,
        name: "Tônica",
        image: product68,
        price: 7.9,
        subCategories: ["Bebida", "Sem álcool", "Gelado"],
      },
    ],
  },
];
