import { useState, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import Modal from "../modal/modal.component";
import Product from "../product/product.component";
import missingImage from "../../assets/missing-image.jpg";

import "./special.styles.scss";

const NINA_SPECIALS = 3;

const Special = ({ categories }) => {
  const modalRef = useRef("");
  const [modalProduct, setModalProduct] = useState({
    id: 0,
    image: missingImage,
    name: "",
    strength: 0,
    price: 0,
    flavors: [],
    subCategories: [],
  });

  const responsive = {
    desktop1: {
      breakpoint: { max: 5000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1200, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const scrollWithOffset = (el) => {
    console.log("Chamou");
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = -128;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <section className="special" id="media">
      {categories.map(
        ({ id, name, products }) =>
          id === NINA_SPECIALS && (
            <div key={id}>
              <h2 className="special__title">{name}</h2>
              <div className="standard-alignment">
                <div className="special__products">
                  <Carousel responsive={responsive} infinite={true}>
                    {products.map((product) => {
                      return (
                        <Product
                          key={product.id}
                          product={product}
                          modalRef={modalRef}
                          setModalProduct={setModalProduct}
                        />
                      );
                    })}
                  </Carousel>
                </div>
                <div className="special__container">
                  <HashLink
                    className="special__link"
                    to="/menu"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Ver cardápio
                  </HashLink>
                </div>
              </div>
            </div>
          )
      )}
      <Modal modalRef={modalRef} modalProduct={modalProduct} />
    </section>
  );
};

export default Special;
